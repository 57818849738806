<template>
  <div class="padding-page">
    <div class="opinion bg-white">
      <CommonTitle title="意见描述" />
      <van-field
        v-model="feedbackInfo.content"
        rows="6"
        label=""
        type="textarea"
        placeholder="请填写您对我们产品的建议，以便为您提供更好的服务！"
        maxlength="200"
        show-word-limit
      />
    </div>
    <div class="image bg-white">
      <CommonTitle
        title="图片"
        subtitle="（选填，提供问题截图，最多允许四张）"
      />
      <div class="upload">
        <van-uploader
          v-model="fileList"
          :before-read="beforeRead"
          :before-delete="beforeDelete"
          :max-count="4"
        />
      </div>
    </div>
    <div class="contact">
      <div class="title">联系方式</div>
      <van-field
        class="border-r4"
        v-model="feedbackInfo.phone"
        label=""
        type="text"
        placeholder="请填写您的联系方式"
      />
    </div>
    <div class="btn">
      <van-button type="primary" @click="submit">提交</van-button>
    </div>
  </div>
</template>

<script>
import CommonTitle from "@components/CommonTitle";
import {
  addFeedback,
  uploadFeedbackImage,
  deleteFeedbackImage,
} from "../../http/api";
export default {
  name: "feedback",
  components: {
    CommonTitle,
  },

  data() {
    return {
      fileList: [],
      feedbackInfo: {
        content: "",
        phone: "",
      },
    };
  },

  methods: {
    beforeRead(file) {
      console.log(file);
      let newFile = file;
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      return new Promise((resolve, reject) => {
        //此时可以自行将文件上传至服务器;
        let params = new FormData();
        params.append("image", newFile);
        uploadFeedbackImage(params).then((res) => {
          this.$toast.clear();
          if (!res.statusCode) {
            this.$toast.error(res.message);
            reject();
          }
          newFile.uploadName = res.data.name;
          resolve(newFile);
        });
      });
    },
    beforeDelete(file) {
      console.log(file);
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      return new Promise((resolve, reject) => {
        deleteFeedbackImage({ name: file.file.uploadName }).then((res) => {
          this.$toast.clear();
          if (!res.statusCode) {
            this.$toast.error(res.message);
            reject();
          }
          resolve();
        });
      });
    },
    submit() {
      if (!this.feedbackInfo.content.trim()) {
        this.$toast("意见描述不能为空");
        return;
      }
      let pic = this.fileList.map((item) => {
        return item.file.uploadName;
      });

      this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      addFeedback({
        ...this.feedbackInfo,
        pic: pic.length > 0 ? pic : null,
      }).then((res) => {
        this.$toast.clear();
        this.$toast.success("提交成功");
        this.$router.go(-1);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.padding-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.opinion {
  border-radius: 4px;
  .header {
    padding: 12px 16px;
    font-size: 13px;
    line-height: 18px;
  }
  .van-field {
    font-size: 16px;
  }
}
.image {
  margin-top: 16px;
  .header {
    padding: 12px 16px;
    font-size: 13px;
    line-height: 18px;
  }
}
.upload {
  padding: 16px 24px;
}
.contact {
  flex: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  .title {
    font-weight: 600;
    line-height: 18px;
    font-size: 13px;
  }
  .van-cell {
    margin-top: 12px;
  }
}
.btn {
  width: 100%;
  text-align: center;
  button {
    width: 295px;
    height: 40px;
    margin-bottom: 16px;
    border-radius: 20px;
    background: #33c759;
    font-size: 16px;
  }
}
</style>
